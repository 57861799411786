import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import 'selectize';
import { v4 as uuidv4 } from 'uuid';
import { getSelectedObjectFromChoices } from '@/components/form/serverForm';
import foodEventBus from '@/module/shared/general/utils/eventBus';
import { createLazyLoadingStateEventForId, createSetLazyLoadedChoicesEventForId } from '@/components/form/events';

@Component({
    template: `<select :disabled="disabled" :name="name" :id="id" autocomplete="off" :class="cssClasses">
                     <template v-if="loaded">
                        <slot></slot>
                     </template>
               </select>`
})
export default class VueSelectize extends Vue {
    @Prop({ type: String, default: () => uuidv4().toString() })
    id!: string;

    @Prop({ required: false })
    value!: any;

    @Prop({ required: false })
    initialValue!: any;

    @Prop({ required: false, default: () => [] })
    options!: object[];

    @Prop({ required: false })
    name!: string;

    @Prop({ required: false, default: '' })
    label!: string;

    @Prop({ required: false, type: Function })
    labelCb!: (data, escape) => string;

    @Prop({ required: false, default: '' })
    valueField!: string;

    @Prop({ required: false, default: '' })
    searchField!: string[]|string;

    @Prop({ required: false, default: null })
    disabledField!: string|null;

    @Prop({ required: false, default: '' })
    optGroupField!: string;

    @Prop({ required: false, default: false })
    disabled!: boolean;

    @Prop({ required: false, type: Function })
    createCb!: (input: string, callback?: Function) => object;

    @Prop({ type: Boolean, required: false, default: false })
    allowAdd!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    waitForOptions!: boolean;

    @Prop({ type: Array, required: false })
    classes!: Array<string>;

    @Prop({ type: Boolean, required: false, default: true })
    lazyLoaded!: boolean;

    @Prop({ type: String, default: 'onClick' })
    lazyLoadTrigger!: string;

    loadingOptions = false;

    loaded = false;

    init(callback?: () => void) {
        const opts: Selectize.IOptions<any, any> = {
            onChange: (value) => {
                this.$emit('input', value);
                this.$emit('change-object', this.getSelectedObject(value));
                this.$emit('change', value);
                this.nativeEvent('change').call(this);
            },
            onDropdownOpen: () => {
                if (!this.loadingOptions && this.lazyLoadTrigger === 'onClick' && this.lazyLoaded) {
                    this.$emit('load-lazy-choices');
                }
            },
            onInitialize: () => {
                callback && callback();
            },
            create: this.createCb ? this.createCb : this.allowAdd,
            onFocus: () => {
                if (!this.loadingOptions && this.lazyLoaded && this.lazyLoadTrigger === 'onClick' && this.options.length === 0) {
                    this.$emit('load-lazy-choices');
                }

                // Call the native event binding
                this.nativeEvent('focus').call(this);
            },
            onBlur: this.nativeEvent('blur').bind(this),
            plugins: ['remove_button'],
            allowEmptyOption: true,
            render: {}
        };

        if (this.options.length) {
            opts['options'] = this.processOptions(this.options);
        }

        if (this.valueField.length) {
            opts['valueField'] = this.valueField;
        }

        if (this.label.length) {
            opts['labelField'] = this.label;

            if (!this.searchField.length) {
                opts['searchField'] = this.label;
            }
        }

        if (this.searchField.length) {
            opts['searchField'] = this.searchField;
        }

        if (this.optGroupField.length) {
            opts['optgroupField'] = this.optGroupField;
        }

        if (this.disabledField !== null) {
            opts['disabledField'] = this.disabledField;
        }

        if (this.labelCb) {
            opts.render['option'] = this.labelCb;
            opts.render['item'] = this.labelCb;
        }

        $(this.$el).selectize(opts);
        if (this.$slots.default) {
            const val = $(this.$el).val();

            this.$emit('input', val);
            this.$emit('change-object', this.getSelectedObject(val));
        }

        if (this.value && this.selectize) {
            this.selectize().setValue(this.value);
        }

        if (this.disabled && this.selectize) {
            this.selectize().disable();
        }
    }

    get cssClasses() {
        const classes = ['form-control', 'no-selectize'];

        if (this.classes) {
            for (const cssClass of this.classes) {
                classes.push(cssClass);
            }
        }

        return classes;
    }

    selectize(): Selectize.IApi<any, any> {
        return document.getElementById(this.id)?.selectize;
    }

    setLoading(loading: boolean) {
        const selectizeControl = document.querySelector(`#${this.id}+.selectize-control`);

        if (!selectizeControl) {
            return;
        }

        if (loading) {
            selectizeControl.classList.add('loading');
        } else {
            selectizeControl.classList.remove('loading');
        }
    }

    getSelectedObject(value) {
        return getSelectedObjectFromChoices(value, this.options, this.valueField);
    }

    nativeEvent(eventName: string) {
        return () => {
            const event = new Event(eventName, { bubbles: true });
            this.$el.dispatchEvent(event);
        };
    }

    mounted() {
        if (!this.lazyLoaded && this.waitForOptions && !this.options.length && !this.$slots.default) {
            return;
        }
        this.loaded = true;
        this.$nextTick(() => {
            this.init();
        });


        if (this.lazyLoaded) {
            foodEventBus.subscribe(createSetLazyLoadedChoicesEventForId(this.id), (event) => {
                const selectizeEl = this.selectize();

                this.onOptionsChange(event.payload.choices, this.options);
                this.loadingOptions = true;
                this.setLoading(false);
                if (this.lazyLoadTrigger === 'onClick') {
                    selectizeEl.open();
                }

                window.setTimeout(() => {
                    if (this.lazyLoadTrigger === 'onClick') {
                        selectizeEl.focus();
                    }
                    this.loadingOptions = false;
                }, 150);
            });

            foodEventBus.subscribe(createLazyLoadingStateEventForId(this.id), (event) => {
                this.loadingOptions = true;
                this.setLoading(true);
            });

            if (this.lazyLoadTrigger === 'onMount') {
                this.$nextTick(() => {
                    this.$emit('load-lazy-choices');
                });
            }

        }
    }

    beforeDestroy() {
        if (typeof this.selectize() === 'undefined') return;
        this.selectize().destroy();
    }

    processOptions(options: any[]) {
        if (options.length && typeof options[0] === 'string') {
            options = options.map(item => { return { text: item, value: item }; });
        }

        return options;
    }

    @Watch('disabled')
    onDisabledStateChange() {
        if (typeof this.selectize() === 'undefined') {
            return;
        }

        if (this.disabled) {
            this.selectize().disable();
        } else {
            this.selectize().enable();
        }
    }

    @Watch('options')
    onOptionsChange(val, oldVal) {
        const setOptions = () => {
            const selectizeEl = this.selectize();

            selectizeEl.clear();
            selectizeEl.clearOptions();
            selectizeEl.addOption(this.processOptions(val));
            selectizeEl.refreshOptions(false);

            selectizeEl.setValue(this.value);

            this.onDisabledStateChange();
        };

        if (typeof (this.$el as any).selectize === 'undefined' && val.length) {
            this.init(setOptions);
            return;
        }

        if (typeof (this.$el as any).selectize === 'undefined' && !val.length) {
            return;
        }

        setOptions();
    }

    @Watch('value')
    onValueChange(value) {
        if (!this.selectize()) {
            return;
        }

        if (value === null || typeof value === 'undefined') {
            this.selectize().clear();
        } else {
            this.selectize().setValue(value, true);
        }
    }

    @Watch('initialValue')
    onInitialValueChange(value, oldValue) {
        this.selectize().setValue(value);
    }
}
